import Link from "next/link";
import HeaderEight from "../Header-Top/HeaderTop-Eight";
import { useSelector } from "react-redux";
import { useAppContext } from "../../../context/Context";

const HeaderRightTwo = ({ btnClass, btnText, userType }) => {
  const { mobile, setMobile, search, setSearch, cartToggle, setCart } = useAppContext();

  const { total_items } = useSelector((state) => state.CartReducer);

  return (
    <div className="header-right">
      <style jsx>{`
        /* CSS to hide or show the menu icon based on screen width */
        @media (max-width: 792px) {
          .mobile-menu-bar {
            display: block; /* Show mobile menu bar */
          }

          .desktop-menu-bar {
            display: none; /* Hide desktop menu if you have one */
          }
        }

        @media (min-width: 793px) {
          .mobile-menu-bar {
            display: none; /* Hide mobile menu bar */
          }

          .desktop-menu-bar {
            display: block; /* Show desktop menu if you have one */
          }
        }

        .header-right {
          display: flex;
          align-items: center;
        }

        .quick-access {
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          gap: 10px;
        }

        .access-icon {
          cursor: pointer;
        }

        .hamberger-button {
          background: none;
          border: none;
          cursor: pointer;
          color: white; /* Change hamburger icon color to white */
        }

        .rbt-round-btn {
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .feather-menu {
          color: white; /* Ensure the icon color is also white */
        }
      `}</style>

      <div className="hamberger">
        <button
          className="hamberger-button rbt-round-btn"
          onClick={() => setMobile(!mobile)}
        >
          <i className="feather-menu"></i>
        </button>
      </div>
    </div>
  );
};

export default HeaderRightTwo;

import Link from "next/link";
import Separator from "../Common/Separator";
import { useTranslation } from "next-i18next";

const CopyRight = () => {

  const { t } = useTranslation('common');

  return (
    <>
      <Separator />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="ptb--20"
      >
        <p className="rbt-link-hover text-center text-lg-start">
          Copyright © 2024 <Link href="#">© {t('footer.name')}</Link> {t('footer.copyright')}
        </p>
      </div>
    </>
  );
};

export default CopyRight;

import { useRouter } from 'next/router';
import Image from 'next/image';

export default function LanguageSwitcher() {
    const router = useRouter();
    const { locale } = router;

    const changeLanguage = (newLocale) => {
        router.push(router.pathname, router.asPath, { locale: newLocale });
    };

    const styles = {
        container: {
            display: 'flex',
            gap: '15px',
            alignItems: 'center',
            backgroundColor: '#ffffff',
            border: '1px solid #e0e0e0',
            padding: '5px 10px',
            borderRadius: '5px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        },
        option: {
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            cursor: 'pointer',
            padding: '3px 8px',
            borderRadius: '3px',
            transition: 'background-color 0.2s',
        },
        optionHover: {
            backgroundColor: '#f0f0f0',
        },
        active: {
            backgroundColor: '#e6f7ff',
            border: '1px solid #1890ff',
        },
        text: {
            fontSize: '11px',
            fontWeight: 500,
        },
        // Media query for screens with width less than 600px
        '@media (max-width: 600px)': {
            container: {
                gap: '10px',
                padding: '3px 6px',
            },
            option: {
                padding: '2px 6px',
            },
            text: {
                fontSize: '12px',
            },
        },
        // Additional media query for screens with width less than 530px
        '@media (max-width: 530px)': {
            text: {
                fontSize: '7px', // Two times smaller than the original font size
            },
        },
    };

    return (
        <div style={styles.container}>
            <div
                style={{ ...styles.option, ...(locale === 'tj' ? styles.active : {}) }}
                onClick={() => changeLanguage('tj')}
            >
                <Image src="/images/icons/tj.png" alt="TJ" width={20} height={14} />
                <span style={styles.text}>Тоҷикӣ</span>
            </div>
            <div
                style={{ ...styles.option, ...(locale === 'ru' ? styles.active : {}) }}
                onClick={() => changeLanguage('ru')}
            >
                <Image src="/images/icons/ru.png" alt="RU" width={20} height={14} />
                <span style={styles.text}>Русский</span>
            </div>
            <div
                style={{ ...styles.option, ...(locale === 'en' ? styles.active : {}) }}
                onClick={() => changeLanguage('en')}
            >
                <Image src="/images/icons/uk.png" alt="EN" width={20} height={14} />
                <span style={styles.text}>English</span>
            </div>
        </div>
    );
}

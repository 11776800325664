import { useRouter } from "next/router";
import Link from "next/link";
import { useState, useEffect } from "react";

const Nav = () => {
  const [menuData, setMenuData] = useState([]);
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const router = useRouter();
  const { locale } = router;

  const loadMenuData = async (lang) => {
    const langFile = lang || "tj"; // Default to Tajik if no locale is provided
    try {
      const data = await import(`../../data/MegaMenu_${langFile}.json`);
      setMenuData(data.menuData);
    } catch (error) {
      console.error(`Error loading menu data for locale ${langFile}:`, error);
    }
  };


  useEffect(() => {
    loadMenuData(locale);
    setActiveMenuItem(null); // Reset active menu item when language changes
    setActiveSubMenu(null);  // Reset active sub-menu when language changes
  }, [locale]);

  const toggleMenuItem = (item) => {
    setActiveMenuItem(activeMenuItem === item ? null : item);
    setActiveSubMenu(null);
  };

  const toggleSubMenuItem = (index) => {
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };

  const toggleMobileMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1200);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">

        {/* Main Menu Items */}
        <li className="with-megamenu position-static">
          <Link href="/">
            {locale === "ru" ? "Главная" : locale === "en" ? "Home" : "Асосӣ"}
          </Link>
        </li>

        {/* Марказ */}
        <li className="has-dropdown has-menu-child-item">
          <Link href="#" onClick={(e) => {
            e.preventDefault();
            toggleMenuItem("services");
          }}>
            {locale === "ru" ? "Центр" : locale === "en" ? "Center" : "Марказ"}
            <i className="feather-chevron-down"></i>
          </Link>
          <ul className={`submenu ${activeMenuItem === "services" ? "open" : ""}`}>
            {menuData &&
              menuData.map((data, index) => {
                if (data.menuType === "default-dropdown") {
                  return (
                    data.menuItems &&
                    data.menuItems.map((value, innerIndex) => (
                      <li
                        key={innerIndex}
                        className={`has-dropdown ${activeSubMenu === innerIndex ? "open" : ""}`}
                      >
                        <Link href="#" onClick={(e) => {
                          e.preventDefault();
                          toggleSubMenuItem(innerIndex);
                        }}>
                          {value.title}
                        </Link>

                        {value.hasSubmenu && (
                          <ul className="submenu">
                            {value.submenuItems &&
                              value.submenuItems.map((submenuItem, submenuIndex) => (
                                <li key={submenuIndex}>
                                  <Link href={submenuItem.link}>
                                    {submenuItem.title}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        )}
                      </li>
                    ))
                  );
                }
                return null;
              })}
          </ul>
        </li>

        {/* Иттилоот */}
        <li className="has-dropdown has-menu-child-item">
          <Link href="#" >
            {locale === "ru" ? "Информация" : locale === "en" ? "Information" : "Иттилоот"}
            <i className="feather-chevron-down"></i>
          </Link>
          <ul className={`submenu ${activeMenuItem === "news" ? "open" : ""}`}>
            {menuData &&
              menuData.map((data, index) => {
                if (data.menuType === "default-dropdown1") {
                  return (
                    data.menuItems &&
                    data.menuItems.map((value, innerIndex) => (
                      <li
                        key={innerIndex}
                        className={`has-dropdown ${activeSubMenu === innerIndex ? "open" : ""}`}
                      >
                        <Link href={value.link} >
                          {value.title}
                        </Link>
                      </li>
                    ))
                  );
                }
                return null;
              })}
          </ul>
        </li>

        {/* Олимпиада */}
        <li className="has-dropdown has-menu-child-item">
          <Link href="#" >
            {locale === "ru" ? "Олимпиада" : locale === "en" ? "Olympiad" : "Олимпиада"}
            <i className="feather-chevron-down"></i>
          </Link>
          <ul className={`submenu ${activeMenuItem === "olymp" ? "open" : ""}`}>
            {menuData &&
              menuData.map((data, index) => {
                if (data.menuType === "olymp") {
                  return (
                    data.menuItems &&
                    data.menuItems.map((value, innerIndex) => (
                      <li
                        key={innerIndex}
                        className={`has-dropdown ${activeSubMenu === innerIndex ? "open" : ""}`}
                      >
                        <Link href={value.link} >
                          {value.title}
                        </Link>
                      </li>
                    ))
                  );
                }
                return null;
              })}
          </ul>
        </li>

        {/* Муассисаҳои типи нав */}
        <li className="has-dropdown has-menu-child-item">
          <Link href="#" >
            {locale === "ru" ? "Новые типы учреждений" : locale === "en" ? "New Type Institutions" : "Муассисаҳои типи нав"}
            <i className="feather-chevron-down"></i>
          </Link>
          <ul className={`submenu ${activeMenuItem === "mtn" ? "open" : ""}`}>
            {menuData &&
              menuData.map((data, index) => {
                if (data.menuType === "mtn") {
                  return (
                    data.menuItems &&
                    data.menuItems.map((value, innerIndex) => (
                      <li
                        key={innerIndex}
                        className={`has-dropdown ${activeSubMenu === innerIndex ? "open" : ""}`}
                      >
                        <Link href={value.link} >
                          {value.title}
                        </Link>
                      </li>
                    ))
                  );
                }
                return null;
              })}
          </ul>
        </li>

        {/* Санадҳои меъёрӣ-ҳуқуқӣ */}
        <li className="with-megamenu position-static">
          <Link href="/more/sanad" >
            {locale === "ru" ? "Нормативно-правовые акты" : locale === "en" ? "Regulatory-legal acts" : "Санадҳои меъёрӣ-ҳуқуқӣ"}
          </Link>
        </li>

        {/* Маводи методӣ */}
        <li className="has-dropdown has-menu-child-item">
          <Link href="#" >
            {locale === "ru" ? "Методические материалы" : locale === "en" ? "Methodical Materials" : "Маводи методӣ"}
            <i className="feather-chevron-down"></i>
          </Link>
          <ul className={`submenu ${activeMenuItem === "mavod" ? "open" : ""}`}>
            {menuData &&
              menuData.map((data, index) => {
                if (data.menuType === "mavod") {
                  return (
                    data.menuItems &&
                    data.menuItems.map((value, innerIndex) => (
                      <li
                        key={innerIndex}
                        className={`has-dropdown ${activeSubMenu === innerIndex ? "open" : ""}`}
                      >
                        <Link href={value.link} >
                          {value.title}
                        </Link>
                      </li>
                    ))
                  );
                }
                return null;
              })}
          </ul>
        </li>


        {/* Тамос */}
        <li className="has-dropdown has-menu-child-item">
          <Link href="#" >
            {locale === "ru" ? "Контакты и приём" : locale === "en" ? "Contact and Reception" : "Тамос ва қабул"}
            <i className="feather-chevron-down"></i>
          </Link>
          <ul className={`submenu ${activeMenuItem === "tamos" ? "open" : ""}`}>
            {menuData &&
              menuData.map((data, index) => {
                if (data.menuType === "tamos") {
                  return (
                    data.menuItems &&
                    data.menuItems.map((value, innerIndex) => (
                      <li
                        key={innerIndex}
                        className={`has-dropdown ${activeSubMenu === innerIndex ? "open" : ""}`}
                      >
                        <Link href={value.link} >
                          {value.title}
                        </Link>
                      </li>
                    ))
                  );
                }
                return null;
              })}
          </ul>
        </li>


      </ul>
      {/* CSS Styles */}
      <style jsx>{`
      
      /* Responsive Styles */
      @media (max-width: 1200px) {
        .mainmenu {
          display: none; /* Hide menu on mobile */
        }
        .mainmenu-nav {
          display: none; /* Hide menu on mobile */
        }
      }
    `}</style>


    </nav>
  );
};

export default Nav;

import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import HeaderTopData from "../../../data/headerTop";
import { useAppContext } from "../../..//context/Context";
import { useRouter } from "next/router";
import { useEffect } from "react";

const HeaderTopEight = ({
  bgColor,
  gapSpaceBetween,
  container,
  flexDirection,
}) => {
  const router = useRouter();
  const { toggle, setToggle } = useAppContext();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredSubmenuIndex, setHoveredSubmenuIndex] = useState(null);

  useEffect(() => {
    const menuItems = document.querySelectorAll(".has-child-menu");

    menuItems.forEach((item) => {
      item.addEventListener("mouseenter", () => {
        const submenu = item.querySelector(".submenu");
        if (submenu) {
          submenu.style.display = "block";
        }
      });

      item.addEventListener("mouseleave", () => {
        const submenu = item.querySelector(".submenu");
        if (submenu) {
          submenu.style.display = "none";
        }
      });
    });

    // Cleanup function to remove event listeners
    return () => {
      menuItems.forEach((item) => {
        item.removeEventListener("mouseenter", () => { });
        item.removeEventListener("mouseleave", () => { });
      });
    };
  }, []);

  return (
    HeaderTopData &&
    HeaderTopData.headerTopOne &&
    HeaderTopData.headerTopOne.map((item, index) => (
      <div
        style={{
          zIndex: '2000'
        }}
        className={`top-expended-inner rbt-header-sec align-items-center ${flexDirection}`}
        key={index}
      >
        <div className="rbt-header-sec-col rbt-header-right mt_md--0 mt_sm--0">
          <div className="rbt-header-content justify-content-start justify-content-lg-end">
            <div className="header-info">
              <ul
                className="rbt-dropdown-menu switcher-language"
                style={{
                  position: "relative",
                  listStyle: "none",
                  padding: 0,
                  margin: 0,
                }}
              >
                {item &&
                  item.language &&
                  item.language.map((lng, innerIndex) => (
                    <li
                      className="has-child-menu"
                      key={innerIndex}
                      onMouseEnter={() => setHoveredIndex(innerIndex)}
                      onMouseLeave={() => setHoveredIndex(null)}
                      style={{
                        position: "relative",
                        cursor: "pointer",
                        transition: "all 0.3s ease",
                      }}
                    >
                      <Link
                        href={lng.link}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          textDecoration: "none",
                          color: "inherit",
                          padding: "5px 10px",
                          transition: "background-color 0.3s ease",
                          borderRadius: "5px",
                          backgroundColor:
                            hoveredIndex === innerIndex ? "#f0f0f0" : "inherit",
                        }}
                      >
                        <Image
                          className="left-image"
                          src={lng.img}
                          width={20}
                          height={13}
                          alt={`${lng.defaultTitle} Images`}
                          style={{
                            transform:
                              hoveredIndex === innerIndex ? "scale(1.1)" : "scale(1)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                        <span
                          className="menu-item"
                          style={{ marginLeft: "5px" }}
                        >
                          {lng.defaultTitle}
                        </span>
                        <i
                          className="right-icon feather-chevron-down"
                          style={{ marginLeft: "5px" }}
                        ></i>
                      </Link>

                      {/* Render submenu for each language */}
                      <ul
                        className="submenu"
                        style={{
                          display: hoveredIndex === innerIndex ? "block" : "none",
                          position: "absolute",
                          top: "100%",
                          left: 0,
                          backgroundColor: "white",
                          listStyle: "none",
                          padding: "0",
                          margin: 0,
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                          borderRadius: "5px",
                          overflow: "hidden",
                          zIndex: 1000,
                          opacity: hoveredIndex === innerIndex ? 1 : 0,
                          visibility: hoveredIndex === innerIndex ? "visible" : "hidden",
                          transition: "opacity 0.3s ease, visibility 0.3s ease",
                        }}
                      >
                        {lng.subLng.map((sublng, subIndex) => (
                          <li
                            key={subIndex}
                            onMouseEnter={() => setHoveredSubmenuIndex(subIndex)}
                            onMouseLeave={() => setHoveredSubmenuIndex(null)}
                          >
                            <Link
                              href={sublng.link}
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                padding: "10px",
                                paddingLeft: "10px",
                                textDecoration: "none",
                                color: "inherit",
                                transition: "background-color 0.3s ease",
                                backgroundColor:
                                  hoveredSubmenuIndex === subIndex
                                    ? "#f0f0f0"
                                    : "inherit",
                              }}
                            >
                              <Image
                                className="left-image"
                                src={sublng.img}
                                width={20}
                                height={13}
                                alt={`${sublng.name} Image`}
                                style={{
                                  transform:
                                    hoveredSubmenuIndex === subIndex
                                      ? "scale(1.1)"
                                      : "scale(1)",
                                  transition: "transform 0.3s ease",
                                }}
                              />
                              <span style={{ marginLeft: "5px", textAlign: 'start', width: '60px' }}>
                                {sublng.name}
                              </span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    ))
  );
};

export default HeaderTopEight;
